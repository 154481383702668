import React from 'react';

const NoAppointments = () => {
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: 20 }}>
      No appointments were found. Please try changing the filters or search criteria
    </div>
  );
};

export default NoAppointments;
