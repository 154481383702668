import { styled } from '@mui/material';

export const Wrapper = styled('ul')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: '12px',
  margin: '12px 0 0',
  padding: 0,
  listStyle: 'none',
  '& .service-item': {
    textTransform: 'capitalize',
  },
});
