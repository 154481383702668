import { Button, styled } from '@mui/material';

export const Price = styled('span')(() => ({
  fontSize: 20,
  fontWeight: 600,
  color: '#202021',
}));

export const SelectButton = styled(Button)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#202021',
  padding: '9px 18px',
}));
