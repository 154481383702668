import { TRole } from '../../../store/reducers/users/types';

export const DashboardsIds = {
  AppointmentSummary: 'Bioab5mEC',
  ShopLoading: 'dXaJXN4Bx',
  ValetAppointments: '-p7lXERDK',
  MobileServiceAppointments: 'KILliymYmVIBMazCnOQMG',
  CustomerBehavior: 'psDZZLXdRm3CJXaZUPbnm',
  RepairOrderPerformance: 'oK0YxEfcoDkhsmMtFjGxo',
  CapacityManagementPerformance: 'nO0UhbMtl58MxO59VCC3x',
};

export const reportingAllowedRoles: TRole[] = [
  'Service Director',
  'Owner',
  'Super Admin',
  'Manager',
];
