import { TForm } from './types';

export const initialForm: TForm = {
  serviceBookName: '',
  gapSlotsType: null,
  appointmentsPerSlot: null,
  appointmentLeadTime: null,
  technicianEfficiency: null,
  avarageBillHoursPerRO: null,
  cutOffTime: [],
};
export const daysList = [0, 1, 2, 3, 4, 5, 6];
