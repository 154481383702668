import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { frameTheme } from '../../../theme/theme';
import { YearModel } from '../../../features/booking/AppointmentFlow/Screens/ValueService/YearModel/YearModel';
import {
  setCurrentFrameScreen,
  setVehicleDataFromValueService,
} from '../../../store/reducers/appointmentFrameReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ServiceSelection from '../../../features/booking/AppointmentFlow/Screens/ValueService/ServiceSelection/ServiceSelection';
import ServiceDetails from '../../../features/booking/AppointmentFlow/Screens/ValueService/ServiceDetails/ServiceDetails';
import { RootState } from '../../../store/rootReducer';
import { loadSCProfile } from '../../../store/reducers/appointment/actions';
import { decodeSCID } from '../../../utils/utils';
import { EServiceType } from '../../../store/reducers/appointmentFrameReducer/types';
import { TScreen } from '../../../types/types';
import { TValueServiceScreen } from './types';
import { Container } from './styles';

type TValueServiceProps = {
  onBack: () => void;
  nextScreen: TScreen;
};

const ValueService: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<TValueServiceProps>>
> = ({ onBack, nextScreen }) => {
  const [screen, setScreen] = useState<TValueServiceScreen>('vehicleDetails');
  const { serviceTypeOption } = useSelector((state: RootState) => state.appointmentFrame);
  const { customerLoadedData } = useSelector((state: RootState) => state.appointment);
  const { config } = useSelector((state: RootState) => state.bookingFlowConfig);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const serviceType = useMemo(
    () => (serviceTypeOption ? serviceTypeOption.type : EServiceType.VisitCenter),
    [serviceTypeOption]
  );
  const isServiceDetailsPageOn = useMemo(() => {
    return Boolean(
      config.find(item => item.serviceType.toString() === serviceType.toString())
        ?.productPageForValueService
    );
  }, [config, serviceType]);

  const history = useHistory();

  useEffect(() => {
    dispatch(loadSCProfile(decodeSCID(id)));
  }, [id]);

  const redirect = () => {
    if (id) {
      if (customerLoadedData?.isUpdating) {
        history.push('/f/appointment-manage/' + id);
      } else {
        history.push('/f/appointment/' + id);
      }
    }
  };

  const onNext = async () => {
    await dispatch(setVehicleDataFromValueService());
    await dispatch(setCurrentFrameScreen(nextScreen));
    redirect();
  };

  const onBackClick = () => {
    redirect();
    onBack();
  };

  const component = useMemo(() => {
    const screens: { [k in TValueServiceScreen]: JSX.Element } = {
      vehicleDetails: (
        <YearModel onBack={onBackClick} onNext={() => setScreen('serviceSelection')} />
      ),
      serviceSelection: (
        <ServiceSelection
          onNext={() => (isServiceDetailsPageOn ? setScreen('serviceDetails') : onNext())}
          onBack={() => setScreen('vehicleDetails')}
        />
      ),
      serviceDetails: (
        <ServiceDetails
          onBack={() => setScreen('serviceSelection')}
          onNext={onNext}
          onChangeVehicle={() => setScreen('vehicleDetails')}
        />
      ),
    };
    return screens[screen];
  }, [screen]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={frameTheme}>
        <Container>{component}</Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ValueService;
