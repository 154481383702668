import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  root: {
    color: '#858585',
    marginRight: 10,
    fontSize: 16,
    textTransform: 'none',
  },
});
