import React, { useState } from 'react';
import {
  BaseModal,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../components/modals/BaseModal/BaseModal';
import { TextField } from '../../../../components/formControls/TextFieldStyled/TextField';
import { Chip } from '../../../../components/wrappers/Chip/Chip';
import { DialogProps } from '../../../../components/modals/BaseModal/types';
import { Button, Divider, IconButton } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useStyles } from '../../MakesModels/AddMakeModelModal/styles';
import { useDispatch, useSelector } from 'react-redux';
import { createEngineType } from '../../../../store/reducers/vehicleDetails/actions';
import { TCreateEngineType } from '../../../../store/reducers/vehicleDetails/types';
import { RootState } from '../../../../store/rootReducer';
import { useException } from '../../../../hooks/useException/useException';
import { useSCs } from '../../../../hooks/useSCs/useSCs';

export const AddEngineTypeModal: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<DialogProps>>
> = props => {
  const { engineTypes } = useSelector((state: RootState) => state.vehicleDetails);
  const [newEngineType, setNewEngineType] = useState<string>('');
  const [types, setTypes] = useState<string[]>([]);
  const [formIsChecked, setFormIsChecked] = useState<boolean>(false);
  const { selectedSC } = useSCs();
  const dispatch = useDispatch();
  const showError = useException();
  const { classes } = useStyles();

  const onCancel = () => {
    setNewEngineType('');
    setFormIsChecked(false);
    setTypes([]);
    props.onClose();
  };

  const onEngineTypeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormIsChecked(true);
    setNewEngineType(e.target.value);
  };

  const onEngineTypeDelete = (el: string) => {
    setFormIsChecked(true);
    setTypes(prev => prev.filter(item => item !== el));
  };

  const addEngineType = (): void => {
    setFormIsChecked(true);
    const value = newEngineType.trim();
    if (value?.length) {
      const lowerCaseTypes = types.map(item => item.toLowerCase());
      if (lowerCaseTypes.includes(value.toLowerCase())) {
        return showError(`Engine Type "${value}" already exists`);
      }
      setTypes(prev => [...prev, newEngineType]);
    }
    setNewEngineType('');
  };

  const onKeyUp = (e: React.KeyboardEvent<{}>) => {
    if (e.key === 'Enter') addEngineType();
  };

  const onSave = (): void => {
    setFormIsChecked(true);
    if ((types.length || newEngineType?.length) && selectedSC) {
      const existingTypes = engineTypes.filter(
        item => types.includes(item.name) || item.name === newEngineType
      );
      if (existingTypes.length) {
        return showError(
          `${existingTypes.length > 1 ? 'Engine Types' : 'Engine Type'} "${existingTypes.map(item => item.name).join(', ')}" already exists`
        );
      }
      const data: TCreateEngineType = {
        names: types.length ? types : [newEngineType],
        serviceCenterId: selectedSC.id,
      };
      dispatch(createEngineType(data, err => showError(err)));
      onCancel();
    }
  };

  return (
    <BaseModal {...props} width={540} onClose={onCancel}>
      <DialogTitle onClose={onCancel}>Add Engine Type</DialogTitle>
      <DialogContent>
        {Boolean(types.length) && (
          <div className={classes.modelsWrapper}>
            {types.map(type => (
              <Chip key={type} name={type} onDelete={onEngineTypeDelete} />
            ))}
          </div>
        )}
        <div className={classes.addModel} role="presentation" onKeyUp={onKeyUp}>
          <div style={{ width: '90%' }}>
            <TextField
              fullWidth
              label="Engine Type"
              placeholder="Type Engine Type"
              error={!newEngineType && !types.length && formIsChecked}
              onChange={onEngineTypeChange}
              value={newEngineType}
            />
          </div>
          <IconButton onClick={addEngineType} className={classes.iconPlus} size="large">
            <AddCircleOutline />
          </IconButton>
        </div>
      </DialogContent>
      <Divider style={{ margin: 0 }} />
      <DialogActions>
        <div className={classes.wrapper}>
          <div className={classes.buttonsWrapper}>
            <Button onClick={onCancel} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={onSave} className={classes.saveButton}>
              Save
            </Button>
          </div>
        </div>
      </DialogActions>
    </BaseModal>
  );
};
