import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { ServiceCodes } from '../ServiceCodes/ServiceCodes';
import MaintenancePackages from '../MaintenancePackages/MaintenancePackages';
import { TabContext, TabPanel } from '@mui/lab';
import { TabList } from '../../../../components/styled/Tabs';
import { SquarePaper } from '../../../../components/styled/Paper';
import { Wrapper } from './styles';

type Tab = {
  id: string;
  label: string;
  component: JSX.Element;
};
const tabs: Tab[] = [
  { id: '0', label: 'OP CODES ELIGIBILITY', component: <ServiceCodes /> },
  { id: '1', label: 'MAINTENANCE PACKAGES ELIGIBILITY', component: <MaintenancePackages /> },
];

const EligibilityStatuses = () => {
  const [selectedTab, selectTab] = useState<string>('0');

  const handleTabChange = (e: any, value: string) => {
    selectTab(value);
  };

  return (
    <SquarePaper variant="outlined">
      <Wrapper>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} indicatorColor="primary">
            {tabs.map(t => {
              return <Tab label={t.label} value={t.id} key={t.id} />;
            })}
          </TabList>
        </TabContext>
      </Wrapper>

      <TabContext value={selectedTab}>
        {tabs.map(t => {
          return (
            <TabPanel style={{ width: '100%', padding: '0' }} key={t.id} value={t.id}>
              {t.component}
            </TabPanel>
          );
        })}
      </TabContext>
    </SquarePaper>
  );
};

export default EligibilityStatuses;
