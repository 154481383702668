export const data = {
  customerData: {
    name: 'Dilan Mannyx',
    address: '1400 U.S. 41, Schererville, 46375',
    phones: ['(310) 555-1318', '38094587557'],
    email: 'mkiii7m@gmail.com',
  },
  serviceCenter: {
    name: 'DealerShipName',
    address: 'Simi Valley, CA 95123',
    phones: ['(311) 556-1812'],
    link: 'dealershipname.com',
    hoursOfOperation:
      'Monday to Friday: 8:00 a.m. to 6:00 p.m. ' + 'Saturday: 9:00 a.m. to 4:00 p.m.',
  },
  vehicleData: {
    year: '2021',
    make: 'FORD',
    model: 'BRONCO',
    vin: 'JY9095495069345',
    mileageIn: '2000',
    mileageOut: '2000',
    license: 'JJ900',
    body: 'HJ749',
    color: 'NAVY',
  },
  repairOrder: {
    number: '6167',
    openedDate: '01/06/22',
    promisedDate: '01/06/22',
    closedDate: '01/06/22',
    serviceAdvisor: 'MICHAEL FREENQIA',
    tagNumber: '195',
    rate: '25',
    payment: '25',
  },
  servicesCompleted: [
    {
      name: 'Battery Test and Replace Failed Battery',
      parts: 321.08,
      labor: 54,
      total: 375.08,
    },
    {
      name: 'Remove Anti Theft Cat Converter Cage Assemply',
      parts: 0,
      labor: 186.85,
      total: 186.85,
    },
    {
      name: 'Perform Multi Point Inspection of Vehicle',
      parts: 0,
      labor: 0,
      total: 0,
    },
    {
      name: 'Completed 75k Service',
      parts: 68.23,
      labor: 186.85,
      total: 255.08,
    },
  ],
  priceWithTaxes: {
    subletAmount: 0,
    shopSupplies: 250,
    hazardousMaterailes: 125,
    totalCharges: 150,
    lessAdjustments: 2850,
    salesTax: 950,
  },
  disclaimerOfWarranties:
    'Restimates provided are an approximation of timing and charges to you for the services requested. They are based on the anticipated work to be done. It is possible for unexpected complications to cause some deviation from the original quote. We need your approval, along with a deposit of $XX,XXX, to begin procurement of necessary material(s) and start the work described in this estimate.\n' +
    'If any additional repairs are required, we will prepare a new estimate with the cost of additional parts, labor, and revised cost. \n' +
    'DEPOSITS ARE NON-REFUNDABLE. No returns or refunds on special-ordered items or electrical parts. All parts are new unless specified otherwise. In the case that used parts or customer-supplied parts are utilized in the completion of this job, there will be no warranty provided for those materials.',
};
