import React from 'react';
import { styled } from '@mui/material';

export const AppointmentConfirmationTitle = styled('h4')({
  fontWeight: 700,
  fontSize: 16,
  textTransform: 'uppercase',
  margin: 0,
  padding: 0,
});
