import React from 'react';
import { ContentContainer } from '../../../components/wrappers/ContentContainer/ContentContainer';
import { OfferManagement } from '../../../features/admin/OfferManagement/OfferManagement';

export const OfferManagementPage = () => {
  return (
    <ContentContainer>
      <OfferManagement />
    </ContentContainer>
  );
};
