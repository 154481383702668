import React from 'react';
import { AppointmentSlotsDesirability } from './AppointmentSlotsDesirability/AppointmentSlotsDesirability';

export const TimeOfDayDesirability = () => {
  return (
    <div>
      <AppointmentSlotsDesirability />
    </div>
  );
};
