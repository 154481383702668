import { TForm } from './types';

export const initialForm: TForm[] = [
  { undesirable: 0, desirable: 0, id: 0 },
  { undesirable: 0, desirable: 0, id: 0 },
  { undesirable: 0, desirable: 0, id: 0 },
  { undesirable: 0, desirable: 0, id: 0 },
  { undesirable: 0, desirable: 0, id: 0 },
  { undesirable: 0, desirable: 0, id: 0 },
];
