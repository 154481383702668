import { styled } from '@mui/material';

export const Title = styled('div')({
  color: '#252733',
  fontSize: 14,
});

export const Text = styled('span')({
  color: '#858585',
  fontSize: 14,
});
