import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BaseModal,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../components/modals/BaseModal/BaseModal';
import { DialogProps } from '../../../../components/modals/BaseModal/types';
import {
  EServiceCategoryType,
  ICategory,
  TNewCategory,
  TUpdateCategoryData,
} from '../../../../store/reducers/categories/types';
import { Button, Divider, FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material';
import { TextField } from '../../../../components/formControls/TextFieldStyled/TextField';
import { autocompleteRender } from '../../../../utils/autocompleteRenders';
import { Autocomplete } from '@mui/material';
import { SearchInput } from '../../../../components/formControls/SearchInput/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadAllAssignedServiceRequests,
  setAssignedFilter,
} from '../../../../store/reducers/serviceRequests/actions';
import { RootState } from '../../../../store/rootReducer';
import {
  IAssignedServiceRequest,
  TOPsCodeWithIndex,
} from '../../../../store/reducers/serviceRequests/types';
import {
  createCategory,
  updateCategory,
  updateCategoryIcon,
} from '../../../../store/reducers/categories/actions';
import { OpsCodesTable } from './OpsCodesTable/OpsCodesTable';
import { FileInput } from '../../../../components/formControls/FileInput/FileInput';
import { loadBookingFlowConfig } from '../../../../store/reducers/bookingFlowConfig/actions';
import { EServiceType } from '../../../../store/reducers/appointmentFrameReducer/types';
import { OpsCodesOrderTable } from './OpsCodesOrderTable/OpsCodesOrderTable';
import { IIconState, TOption } from './types';
import { Label, useStyles } from './styles';
import { categoryOptions, findMissingNumbers, getOptionLabel, getPageOptions } from './utils';
import { visitCenterTabs } from '../constants';
import { useException } from '../../../../hooks/useException/useException';
import { useSCs } from '../../../../hooks/useSCs/useSCs';
import { useMessage } from '../../../../hooks/useMessage/useMessage';
import OpsCodesSelected from './OpsCodesSelected/OpsCodesSelected';

type TAddServiceCategoryProps = DialogProps & {
  editingItem: ICategory | null;
  tabValue: string;
};

const initialFileState = { file: null, dataUrl: undefined };

export const AddServiceCategoryModal: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<TAddServiceCategoryProps>>
> = ({ editingItem, tabValue, ...props }) => {
  const { allAssignedList, assignedFilter } = useSelector(
    (state: RootState) => state.serviceRequests
  );
  const { categories, page, filter } = useSelector((state: RootState) => state.categories);
  const { config } = useSelector((state: RootState) => state.bookingFlowConfig);
  const [fileState, setFileState] = useState<IIconState>(initialFileState);
  const [categoryName, setCategoryName] = useState<string>('');
  const [definedPage, setDefinedPage] = useState<TOption | null>(null);
  const [categoryType, setCategoryType] = useState<TOption | null>(null);
  const [formIsChecked, setFormIsChecked] = useState<boolean>(false);
  const [selectedCodes, setSelectedCodes] = useState<IAssignedServiceRequest[]>([]);
  const [selectedCodesWithOrder, setSelectedCodesWithOrder] = useState<TOPsCodeWithIndex[]>([]);
  const [orderIndex, setOrderIndex] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedServiceType, setSelectedServiceType] = useState<EServiceType>(
    EServiceType.VisitCenter
  );
  const [isCommentRequired, setIsCommentRequired] = useState<boolean>(false);
  const [wrongOrderIndexes, setWrongOrderIndexes] = useState<number[]>([]);
  const tabServiceType = visitCenterTabs.includes(tabValue)
    ? EServiceType.VisitCenter
    : EServiceType.MobileService;

  const { selectedSC } = useSCs();
  const dispatch = useDispatch();
  const showError = useException();
  const showMessage = useMessage();
  const { classes } = useStyles();

  const disabledOpsCodes = useMemo(
    () =>
      categoryType?.value === EServiceCategoryType.MaintenancePackage ||
      categoryType?.value === EServiceCategoryType.LinkToPage2 ||
      categoryType?.value === EServiceCategoryType.ValueService ||
      categoryType?.value === EServiceCategoryType.OpenRecalls,
    [categoryType]
  );

  const visitCenterConfig = useMemo(() => {
    const currentServiceType =
      selectedServiceType === EServiceType.VisitCenter
        ? EServiceType.VisitCenter
        : EServiceType.MobileService;
    return config.find(item => item.serviceType === currentServiceType);
  }, [config, selectedServiceType]);

  const filteredIndCodes = useMemo(
    () => allAssignedList.filter(el => selectedCodesWithOrder.find(item => item.id === el.id)),
    [allAssignedList, selectedCodesWithOrder]
  );

  const categoryHasCodesOrder = useMemo(
    () =>
      categoryType?.value === EServiceCategoryType.IndividualServices ||
      categoryType?.value === EServiceCategoryType.Diagnose,
    [categoryType]
  );

  useEffect(() => {
    setSelectedServiceType(filter);
  }, [filter]);

  const getCategoryOptions = () => {
    let options: TOption[] = categoryOptions;
    if (!selectedSC?.isValueServiceAvailable || !visitCenterConfig?.valueService) {
      options = categoryOptions.filter(o => o.value !== EServiceCategoryType.ValueService);
    }
    if (definedPage?.value === 1) {
      options = categoryOptions.filter(
        o =>
          o.value !== EServiceCategoryType.MaintenancePackage &&
          o.value !== EServiceCategoryType.LinkToPage2
      );
    }
    return options;
  };

  useEffect(() => {
    props.open && selectedSC && dispatch(loadAllAssignedServiceRequests(selectedSC.id));
    const currentPageOption = getPageOptions(selectedServiceType).find(item => item.value === page);
    currentPageOption && setDefinedPage(currentPageOption);
  }, [selectedSC, page, getPageOptions, props.open, selectedServiceType]);

  useEffect(() => {
    if (editingItem && allAssignedList && props.open) {
      setCategoryName(editingItem.name);

      const page = getPageOptions(selectedServiceType).find(
        option => option.value === +editingItem.page
      );
      page && setDefinedPage(page);
      if (
        editingItem.type === EServiceCategoryType.IndividualServices ||
        editingItem.type === EServiceCategoryType.Diagnose
      ) {
        const requests = editingItem.serviceRequests.map(({ id, orderIndex }) => ({
          id,
          orderIndex: orderIndex === undefined ? '0' : orderIndex.toString(),
        }));
        setSelectedCodesWithOrder(requests);
      } else {
        setSelectedCodes(
          allAssignedList.filter(item => editingItem.serviceRequests.find(el => el.id === item.id))
        );
      }

      const currentType = categoryOptions.find(item => item.value === +editingItem.type);
      currentType && setCategoryType(currentType);

      if (editingItem.orderIndex) setOrderIndex(editingItem.orderIndex.toString());
      if (editingItem.description) setDescription(editingItem.description);
      if (editingItem.isCommentRequired) setIsCommentRequired(editingItem.isCommentRequired);
    }
  }, [editingItem, allAssignedList, categoryOptions, props.open, selectedServiceType]);

  useEffect(() => {
    if (selectedSC) {
      dispatch(loadBookingFlowConfig(selectedSC.id));
    }
  }, [dispatch, selectedSC]);

  const onCancel = useCallback(() => {
    setFormIsChecked(false);
    setCategoryName('');
    dispatch(setAssignedFilter({ searchTerm: '' }));
    setFileState(initialFileState);
    setSelectedCodes([]);
    setSelectedCodesWithOrder([]);
    setCategoryType(null);
    setOrderIndex('');
    setDescription('');
    setIsCommentRequired(false);
    setSelectedServiceType(filter);
    setWrongOrderIndexes([]);
    props.onClose();
  }, []);

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onSuccessCreate = useCallback(
    (categoryId: number) => {
      if (fileState.file)
        dispatch(updateCategoryIcon(categoryId, fileState.file, tabServiceType, onError, onCancel));
    },
    [fileState, tabServiceType]
  );

  const onError = (err: any) => {
    if (categoryHasCodesOrder) {
      const orderIndexes = selectedCodesWithOrder.map(item => +item.orderIndex);
      const { wrongNumbers } = findMissingNumbers(orderIndexes);
      setWrongOrderIndexes(wrongNumbers);
    }
    showError(err);
  };

  const onSuccessfulUpdate = () => showMessage('Category updated');

  const onSuccessfulCreate = () => {
    showMessage('Category created');
    onCancel();
  };

  const onSave = useCallback(() => {
    if (selectedSC) {
      setFormIsChecked(true);
      if (
        categoryType?.value === EServiceCategoryType.ValueService &&
        !visitCenterConfig?.valueService
      ) {
        return showError(
          'Value Service Option is turned off in the Booking Flow and cannot be saved'
        );
      }
      if (definedPage?.value === 1) {
        if (categoryType?.value === EServiceCategoryType.MaintenancePackage) {
          return showError(
            'The Category with link to "Maintenance Package" can`t be saved for"Owned By Booking Flow (Page 2)"'
          );
        }
        if (categoryType?.value === EServiceCategoryType.LinkToPage2) {
          return showError(
            'The Category with link to "Link to Page 2" can`t be saved for"Owned By Booking Flow (Page 2)"'
          );
        }
      }

      if (categoryName && definedPage && categoryType && orderIndex) {
        const data: TUpdateCategoryData = {
          name: categoryName,
          page: definedPage.value,
          type: categoryType.value,
          serviceRequests: [],
          orderIndex: Number(orderIndex),
          isCommentRequired:
            categoryType.value === EServiceCategoryType.GeneralCategory ? isCommentRequired : false,
          serviceType: selectedServiceType,
        };
        if (description) data.description = description;
        if (categoryType.value === EServiceCategoryType.GeneralCategory) {
          if (!selectedCodes.length) {
            return showError('Please choose service requests for category');
          } else {
            data.serviceRequests = selectedCodes.map(({ id }) => ({ id }));
          }
        } else if (categoryHasCodesOrder) {
          if (!selectedCodesWithOrder.length) {
            return showError('Please choose service requests for category');
          } else {
            if (selectedCodesWithOrder.every(el => el.orderIndex !== '')) {
              data.serviceRequests = selectedCodesWithOrder.map(el => ({
                ...el,
                orderIndex: +el.orderIndex,
              }));
            } else {
              setWrongOrderIndexes(
                selectedCodesWithOrder.filter(el => el.orderIndex === '').map(el => el.id)
              );
            }
          }
        }
        if (editingItem) {
          if (fileState.file) {
            dispatch(
              updateCategory(editingItem.id, data, tabServiceType, onError, onSuccessfulUpdate)
            );
            dispatch(
              updateCategoryIcon(editingItem.id, fileState.file, tabServiceType, onError, onCancel)
            );
          } else {
            dispatch(
              updateCategory(editingItem.id, data, tabServiceType, onError, () => {
                onCancel();
                onSuccessfulUpdate();
              })
            );
          }
        } else {
          const newData: TNewCategory = { ...data, serviceCenterId: selectedSC.id };
          dispatch(
            createCategory(newData, onSuccessCreate, tabServiceType, onError, onSuccessfulCreate)
          );
        }
      }
    }
  }, [
    selectedSC,
    categoryName,
    definedPage,
    categoryType,
    categoryHasCodesOrder,
    orderIndex,
    selectedCodes,
    editingItem,
    fileState,
    visitCenterConfig,
    description,
    isCommentRequired,
    selectedServiceType,
    tabServiceType,
    selectedCodesWithOrder,
  ]);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormIsChecked(false);
    setCategoryName(e.target.value);
  }, []);

  const onDefinedPageChange = useCallback(
    (e: React.ChangeEvent<{}>, value: TOption | null): void => {
      setFormIsChecked(false);
      setDefinedPage(value);
    },
    []
  );

  const onOrderIndexChange = useCallback((e: React.ChangeEvent<{}>, value: string): void => {
    setFormIsChecked(false);
    setOrderIndex(value);
  }, []);

  const onCategoryTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: TOption | null): void => {
      setFormIsChecked(false);
      setCategoryType(value);
      setSelectedCodes([]);
      setSelectedCodesWithOrder([]);
      setWrongOrderIndexes([]);
    },
    []
  );

  const handleSearch = useCallback(() => {
    if (selectedSC) {
      dispatch(loadAllAssignedServiceRequests(selectedSC.id));
    }
  }, [dispatch, selectedSC]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setAssignedFilter({ searchTerm: e.target.value }));
    },
    [dispatch]
  );

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServiceType(
      e.target.value === '0' ? EServiceType.VisitCenter : EServiceType.MobileService
    );
  };

  const handleSwitch = (e: any, value: boolean) => {
    setIsCommentRequired(value);
  };

  const onDelete = (serviceRequest: IAssignedServiceRequest) => {
    if (categoryHasCodesOrder) {
      setSelectedCodesWithOrder(prev => {
        const codeToChange = prev.find(item => item.id === serviceRequest.id);
        if (codeToChange) {
          if (codeToChange.orderIndex) {
            const data = prev.map(code => ({
              ...code,
              orderIndex:
                +code.orderIndex > +codeToChange.orderIndex
                  ? `${+code.orderIndex - 1}`
                  : code.orderIndex,
            }));
            return data.filter(item => item.id !== serviceRequest.id);
          } else {
            return prev.filter(item => item.id !== serviceRequest.id);
          }
        }
        return prev;
      });
    } else {
      setSelectedCodes(prev => prev.filter(el => el.id !== serviceRequest.id));
    }
  };

  return (
    <BaseModal {...props} width={1128} onClose={onCancel}>
      <DialogTitle onClose={onCancel}>{editingItem ? 'Edit' : 'Add'} Service Category</DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          aria-label="countType"
          name="countType"
          value={selectedServiceType}
          onChange={handleTypeChange}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value={EServiceType.VisitCenter}
            control={<Radio color="primary" />}
            label="VISIT CENTER"
            labelPlacement="end"
          />
          <FormControlLabel
            value={EServiceType.MobileService}
            control={<Radio color="primary" />}
            label="MOBILE SERVICE"
            labelPlacement="end"
          />
        </RadioGroup>
        <div className={classes.inputsWrapper}>
          <div>
            <TextField
              fullWidth
              label="Service Category Name"
              placeholder="Type Service Category Name"
              error={!categoryName && formIsChecked}
              onChange={onNameChange}
              value={categoryName}
            />
          </div>
          <Autocomplete
            options={getPageOptions(selectedServiceType)}
            isOptionEqualToValue={option => option.value === definedPage?.value}
            getOptionLabel={option => option.name}
            value={definedPage}
            onChange={onDefinedPageChange}
            renderInput={autocompleteRender({
              label: 'Define Page',
              placeholder: 'Select a page',
            })}
          />
          <FileInput
            setState={setFileState}
            label={`${fileState.file || editingItem?.iconPath ? 'Update' : 'Upload'} Service Category Icon`}
          />
          <div className={classes.inputWrapper}>
            <label className={classes.label}>Add Op Codes</label>
            <SearchInput
              onSearch={handleSearch}
              onChange={handleSearchChange}
              value={assignedFilter.searchTerm}
            />
          </div>
          <Autocomplete
            options={getCategoryOptions()}
            isOptionEqualToValue={option => option.value === categoryType?.value}
            getOptionLabel={getOptionLabel}
            value={categoryType}
            onChange={onCategoryTypeChange}
            renderInput={autocompleteRender({
              label: 'Link for Booking Flow',
              placeholder: 'Select Link To Screen On Booking Flow',
              error: !categoryType && formIsChecked,
            })}
          />
          <Autocomplete
            disableClearable
            options={categories
              .map((el, index) => `${index + 1}`)
              .concat(`${categories.length + 1}`)}
            value={orderIndex}
            isOptionEqualToValue={(o, v) => o === v}
            onChange={onOrderIndexChange}
            renderInput={autocompleteRender({
              label: 'Order Index for Booking Flow',
              placeholder: 'Select Order Index',
              error: !orderIndex && formIsChecked,
            })}
          />
          <Label
            control={
              <Switch
                disabled={
                  !categoryType || categoryType?.value !== EServiceCategoryType.GeneralCategory
                }
                onChange={handleSwitch}
                checked={isCommentRequired}
                color="primary"
              />
            }
            label="Comment Field Is Required"
            labelPlacement="start"
          />
          <OpsCodesSelected
            selectedCodes={categoryHasCodesOrder ? filteredIndCodes : selectedCodes}
            onDelete={onDelete}
          />
        </div>
        <TextField
          fullWidth
          value={description}
          label="Service Category Description"
          placeholder="Enter Description"
          onChange={onDescriptionChange}
        />
        <Divider />
        {categoryHasCodesOrder ? (
          <OpsCodesOrderTable
            selectedCodes={selectedCodesWithOrder}
            setSelectedCodes={setSelectedCodesWithOrder}
            setWrongOrderIndexes={setWrongOrderIndexes}
            wrongOrderIndexes={wrongOrderIndexes}
            disabled={disabledOpsCodes}
          />
        ) : (
          <OpsCodesTable
            selectedCodes={selectedCodes}
            setSelectedCodes={setSelectedCodes}
            disabled={disabledOpsCodes}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="info">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </BaseModal>
  );
};
