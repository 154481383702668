import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  BaseModal,
  DialogActions,
} from '../../../../components/modals/BaseModal/BaseModal';
import { DialogProps } from '../../../../components/modals/BaseModal/types';
import { ITimeRangeAndCapacity } from '../../../../store/reducers/capacityServiceValet/types';
import { Button, Divider, Grid } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { TextField } from '../../../../components/formControls/TextFieldStyled/TextField';
import { useStyles } from '../../MakesModels/AddMakeModelModal/styles';
import { useDispatch } from 'react-redux';
import {
  createTimeRange,
  updateTimeRange,
} from '../../../../store/reducers/capacityServiceValet/actions';
import { timeWithSecond } from '../constants';
import { useException } from '../../../../hooks/useException/useException';
import { useSCs } from '../../../../hooks/useSCs/useSCs';
import { time24HourFormat } from '../../../../utils/constants';
import { TParsableDate } from '../../../../types/types';
import dayjs from 'dayjs';
import ClockTimePicker from '../../../../components/pickers/ClockTimePicker/ClockTimePicker';

type TProps = DialogProps & {
  editingElement: ITimeRangeAndCapacity;
};

const EditTimeRangeAndCapacityModal: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<TProps>>
> = ({ onClose, open, editingElement }) => {
  const [pickUpMin, setPickUpMin] = useState<TParsableDate>(null);
  const [pickUpMax, setPickUpMax] = useState<TParsableDate>(null);
  const [dropOffMin, setDropOffMin] = useState<TParsableDate>(null);
  const [dropOffMax, setDropOffMax] = useState<TParsableDate>(null);
  const [dailyCapacity, setDailyCapacity] = useState<number | string>('');
  const [formIsChecked, setFormIsChecked] = useState<boolean>(false);
  const { selectedSC } = useSCs();
  const showError = useException();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && editingElement) {
      if (editingElement.pickUpMin !== '-')
        setPickUpMin(dayjs(editingElement.pickUpMin, time24HourFormat));
      if (editingElement.pickUpMax !== '-')
        setPickUpMax(dayjs(editingElement.pickUpMax, time24HourFormat));
      if (editingElement.dropOffMin !== '-')
        setDropOffMin(dayjs(editingElement.dropOffMin, time24HourFormat));
      if (editingElement.dropOffMax !== '-')
        setDropOffMax(dayjs(editingElement.dropOffMax, time24HourFormat));
      if (editingElement.capacity) setDailyCapacity(editingElement.capacity);
    }
  }, [editingElement, open]);

  const onCancel = () => {
    setFormIsChecked(false);
    setPickUpMin(null);
    setPickUpMax(null);
    setDropOffMax(null);
    setDropOffMin(null);
    setDailyCapacity('');
    onClose();
  };

  const handleChangePickUpMin = (date: TParsableDate) => {
    setFormIsChecked(false);
    setPickUpMin(dayjs(date));
  };

  const handleChangePickUpMax = (date: TParsableDate) => {
    setFormIsChecked(false);
    if (dayjs(pickUpMin).diff(dayjs(date)) <= 0) {
      setPickUpMax(dayjs(date));
    } else {
      showError('Pick Up Max Value must be more than Pick Up Min Value');
    }
  };

  const handleChangeDropOffMin = (date: TParsableDate) => {
    setFormIsChecked(false);
    setDropOffMin(dayjs(date));
  };

  const handleChangeDropOffMax = (date: TParsableDate) => {
    setFormIsChecked(false);
    if (dayjs(dropOffMin).diff(dayjs(date)) <= 0) {
      setDropOffMax(dayjs(date));
    } else {
      showError('Drop Off Max Value must be more than Drop Off Min Value');
    }
  };

  const handleChangeDailyCapacity = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormIsChecked(false);
    setDailyCapacity(value ? +value : '');
  };

  const checkIsValid = (): boolean => {
    if (+dailyCapacity <= 0) {
      showError('"Daily Capacity" must be more than 0');
      return false;
    }
    return (
      Boolean(pickUpMin) &&
      Boolean(pickUpMax) &&
      Boolean(dropOffMin) &&
      Boolean(dropOffMax) &&
      +dailyCapacity > 0
    );
  };

  const onSave = () => {
    setFormIsChecked(true);
    if (selectedSC && checkIsValid()) {
      const data: ITimeRangeAndCapacity = {
        serviceCenterId: selectedSC.id,
        pickUpMin: dayjs(pickUpMin).format(timeWithSecond),
        pickUpMax: dayjs(pickUpMax).format(timeWithSecond),
        dropOffMin: dayjs(dropOffMin).format(timeWithSecond),
        dropOffMax: dayjs(dropOffMax).format(timeWithSecond),
        capacity: +dailyCapacity,
      };
      if (editingElement.id) {
        dispatch(updateTimeRange(selectedSC.id, editingElement.id, data, showError, onCancel));
      } else {
        data.dayOfWeek = editingElement.dayOfWeek;
        dispatch(createTimeRange(selectedSC.id, data, showError, onCancel));
      }
    }
  };

  return (
    <BaseModal onClose={onCancel} open={open} width={575}>
      <DialogTitle onClose={onCancel}>
        Edit Time Ranges & Capacity Of
        <span>
          {' '}
          {editingElement.dayOfWeek !== undefined
            ? dayjs().set('day', editingElement.dayOfWeek).format('dddd').toUpperCase()
            : ''}
        </span>
      </DialogTitle>
      <DialogContent style={{ padding: '16px 120px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <ClockTimePicker
              key="pickUpMin"
              value={pickUpMin}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              fullWidth
              InputProps={{
                endAdornment: <AccessTime color="primary" cursor="pointer" />,
                error: formIsChecked && !pickUpMin,
              }}
              name="pickUpMin"
              label="Pick Up Min"
              onChange={handleChangePickUpMin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ClockTimePicker
              key="pickUpMax"
              value={pickUpMax}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              fullWidth
              InputProps={{
                endAdornment: <AccessTime color="primary" cursor="pointer" />,
                error: formIsChecked && !pickUpMax,
              }}
              name="pickUpMax"
              label="Pick Up Max"
              onChange={handleChangePickUpMax}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ClockTimePicker
              key="dropOffMin"
              value={dropOffMin}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              fullWidth
              InputProps={{
                endAdornment: <AccessTime color="primary" cursor="pointer" />,
                error: formIsChecked && !dropOffMin,
              }}
              name="dropOffMin"
              label="Drop Off Min"
              onChange={handleChangeDropOffMin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ClockTimePicker
              key="dropOffMax"
              value={dropOffMax}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              fullWidth
              InputProps={{
                endAdornment: <AccessTime color="primary" cursor="pointer" />,
                error: formIsChecked && !dropOffMax,
              }}
              name="dropOffMax"
              label="Drop Off Max"
              onChange={handleChangeDropOffMax}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Daily Capacity'}
              name="dailyCapacity"
              type="number"
              error={formIsChecked && (+dailyCapacity <= 0 || !dailyCapacity.toString().length)}
              inputProps={{
                min: 0,
              }}
              value={dailyCapacity}
              onChange={handleChangeDailyCapacity}
              fullWidth
              id="dailyCapacity"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider style={{ marginBottom: 0 }} />
      <DialogActions>
        <div className={classes.wrapper}>
          <div className={classes.buttonsWrapper}>
            <Button onClick={onCancel} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={onSave} className={classes.saveButton}>
              Save
            </Button>
          </div>
        </div>
      </DialogActions>
    </BaseModal>
  );
};

export default EditTimeRangeAndCapacityModal;
