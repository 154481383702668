import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { useTranslation } from 'react-i18next';
import { TPackage } from '../types';

type TTotalMaintenanceProps = {
  isBmWService: boolean;
  setClasses: (id: number, cls: string) => string;
  packages: TPackage[];
};

const PackagesTotalMaintenance: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<TTotalMaintenanceProps>>
> = ({ packages, isBmWService, setClasses }) => {
  const { scProfile } = useSelector((state: RootState) => state.appointment);
  const { t } = useTranslation();

  return (
    <React.Fragment key="maintenance">
      <div className="totalMaintenance" style={isBmWService ? { fontSize: 16 } : {}}>
        {t('Total Maintenance Value')}:
      </div>
      {packages.map(p => (
        <div className={setClasses(p.id, '')} key={p.id}>
          <span style={{ fontSize: 20 }}>
            $
            {scProfile?.isRoundPrice ? p.totalMaintenanceValue : p.totalMaintenanceValue.toFixed(2)}
          </span>
        </div>
      ))}
    </React.Fragment>
  );
};

export default PackagesTotalMaintenance;
