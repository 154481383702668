import { styled } from '@mui/material';

export const TableTitle = styled('div')({
  fontSize: 16,
  fontWeight: 700,
  textTransform: 'uppercase',
  padding: '20px 16px',
  backgroundColor: 'white',
  border: '1px solid #DADADA',
  borderBottomWidth: 0,
});
