import { CircularProgress } from '@mui/material';
import React from 'react';

export const LoadingProcess = () => {
  return (
    <div style={{ marginRight: 8 }}>
      <CircularProgress size="1rem" />
    </div>
  );
};
