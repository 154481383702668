import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  label: {
    marginRight: 8,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});
