import React from 'react';
import DemandPredictionTable from '../DemandPredictionTable/DemandPredictionTable';

const DemandPrediction = () => {
  return (
    <div>
      <DemandPredictionTable />
    </div>
  );
};

export default DemandPrediction;
