export enum SliderRange {
  Min = -10,
  Max = 10,
}

export type TForm = {
  undesirable: number;
  desirable: number;
  id: number;
};
