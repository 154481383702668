import { styled } from '@mui/material';

export const InfoWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  textAlign: 'right',
  color: '#008331',
  fontSize: 14,
  fontWeight: 'bold',
  textTransform: 'uppercase',
});
