import { styled } from '@mui/material';

export const StepWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginBottom: 'auto',
  },
}));
