export const headCellStyles = {
  lineHeight: '16px',
  color: '#252733',
  backgroundColor: '#F2F4FB',
  verticalAlign: 'top',
};

export const leftAlign = {
  textAlign: 'left' as const,
};
