import { styled } from '@mui/material';

export const GeographicZonesWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  columnGap: 24,
});

export const TabHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '24px 0 24px 32px',
});
