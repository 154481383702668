import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
});
