import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  wrapper: {
    fontSize: 16,
    color: '#202021',
    fontWeight: 600,
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: 8,
  },
});
