import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  container: {
    width: '100%',
    marginTop: 20,
  },
  panel: {
    padding: '20px 0',
  },
});
