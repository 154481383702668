import { styled } from '@mui/material';

export const FiltersWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2.7fr 2.7fr 1fr',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 12,
  width: '100%',
});
