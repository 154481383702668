import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { TextField } from '../../../../components/formControls/TextFieldStyled/TextField';
import { AvatarUpload } from '../../../../components/formControls/AvatarUpload/AvatarUpload';
import {
  updateDealership,
  updateDealershipAvatar,
} from '../../../../store/reducers/dealershipGroups/actions';
import { useDispatch } from 'react-redux';
import { states } from '../../../../utils/constants';
import { autocompleteRender } from '../../../../utils/autocompleteRenders';
import { Autocomplete } from '@mui/material';
import { validatePhoneNumber } from '../../../../utils/utils';
import { useStyles } from './styles';
import { TForm } from './types';
import { LoadingButton } from '../../../../components/buttons/LoadingButton/LoadingButton';

import { useMessage } from '../../../../hooks/useMessage/useMessage';
import { useException } from '../../../../hooks/useException/useException';
import { useDealershipProfile } from '../../../../hooks/useDealershipProfile/useDealershipProfile';

const blankAddress = { street: '', city: '', zipCode: '', state: '' };

export const DealershipGroupProfile = () => {
  const [nameEdit, setNameEdit] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState<TForm>({
    name: '',
    address: { ...blankAddress },
    phoneNumber: '',
  });
  const profile = useDealershipProfile();
  const showError = useException();
  const showMessage = useMessage();

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();

  useEffect(() => {
    if (profile) {
      setForm({
        phoneNumber: profile.phoneNumber,
        name: profile.name,
        address: profile?.address || { ...blankAddress },
      });
    }
  }, [profile]);

  const handleCancel = () => {
    setForm({
      name: profile?.name || '',
      phoneNumber: profile?.phoneNumber || '',
      address: profile?.address || { ...blankAddress },
    });
    setNameEdit(false);
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'phoneNumber') {
      value = validatePhoneNumber(value);
    }
    setForm({ ...form, [name]: value });
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, address: { ...form.address, [e.target.name]: e.target.value } });
  };

  const handleSelectState = (e: React.ChangeEvent<{}>, val: string | null) => {
    setForm({ ...form, address: { ...form.address, state: val || '' } });
  };

  const handleChangeAvatar = async (f: File) => {
    if (profile) {
      try {
        await dispatch(updateDealershipAvatar(f, profile.id, showError));
        showMessage('Avatar updated');
      } catch (e) {
        showError(e);
      }
    } else {
      showError('Profile is not loaded');
    }
  };

  const handleSave = async () => {
    if (profile) {
      setSaving(true);
      try {
        await dispatch(updateDealership(form, profile.id));
        setSaving(false);
        setNameEdit(false);
        showMessage('Profile updated');
      } catch (e) {
        showError(e);
        setSaving(false);
      }
    } else {
      showError('Profile is not loaded');
    }
  };

  if (!profile) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={5}>
          <div className={classes.avatarContainer}>
            <AvatarUpload onChange={handleChangeAvatar} dataUrl={profile.avatarPath} />
            <span className={classes.title}>{profile.name}</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Dealership Group Name"
            name="name"
            id="name"
            disabled={!nameEdit}
            value={form.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.editButtonContainer}>
          {!nameEdit ? (
            <Button
              className={classes.centerButton}
              variant="contained"
              color="primary"
              onClick={() => setNameEdit(true)}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                style={{ marginRight: 10 }}
                className={classes.centerButton}
                color="info"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <LoadingButton
                fullWidth={false}
                loading={saving}
                className={classes.centerButton}
                color="primary"
                onClick={handleSave}
                variant="contained"
              >
                Save
              </LoadingButton>
            </>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Phone number"
            name="phoneNumber"
            id="phoneNumber"
            disabled={!nameEdit}
            value={form.phoneNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={1} hidden={isSM} />
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="City"
            name="city"
            autoComplete="dealership-city-address dealership-city"
            id="city"
            disabled={!nameEdit}
            value={form.address.city}
            onChange={handleChangeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            fullWidth
            label="Street"
            name="street"
            autoComplete="dealership-street-address dealership-street"
            id="street"
            disabled={!nameEdit}
            value={form.address.street}
            onChange={handleChangeAddress}
          />
        </Grid>
        <Grid item xs={1} hidden={isSM} />
        <Grid item xs={6} sm={6} md={2}>
          <Autocomplete
            options={states}
            disabled={!nameEdit}
            onChange={handleSelectState}
            isOptionEqualToValue={(o, v) => o === v}
            fullWidth
            autoComplete={true}
            renderInput={autocompleteRender({ label: 'State' })}
            value={form.address.state || null}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <TextField
            fullWidth
            label="ZIP Code"
            name="zipCode"
            autoComplete="dealership-zipCode-address dealership-zipCode"
            id="zipCode"
            disabled={!nameEdit}
            value={form.address.zipCode}
            onChange={handleChangeAddress}
          />
        </Grid>
      </Grid>
    </div>
  );
};
