import React, { useState } from 'react';
import { SquarePaper } from '../../../../components/styled/Paper';
import { PaperTitle } from '../../../../pages/admin/ServicePricingSettings/UI';
import { TabContext, TabPanel } from '@mui/lab';
import { TabList } from '../../../../components/styled/Tabs';
import { Tab } from '@mui/material';
import DayOfWeekOpsCode from './DayOfWeekOpsCode/DayOfWeekOpsCode';
import DayOfWeekPackage from './DayOfWeekPackage/DayOfWeekPackage';

type Tab = {
  id: string;
  label: string;
  component: JSX.Element;
};
const tabs: Tab[] = [
  { id: '0', label: 'Day Of Week Op Code', component: <DayOfWeekOpsCode /> },
  { id: '1', label: 'Day Of Week Maintenance Package', component: <DayOfWeekPackage /> },
];

const DayOfWeekTables = () => {
  const [selectedTab, selectTab] = useState<string>('0');

  const handleTabChange = (e: any, value: string) => {
    selectTab(value);
  };

  return (
    <SquarePaper variant="outlined">
      <PaperTitle>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} indicatorColor="primary">
            {tabs.map(t => {
              return <Tab label={t.label} value={t.id} key={t.id} style={{ maxWidth: 350 }} />;
            })}
          </TabList>
        </TabContext>
      </PaperTitle>

      <TabContext value={selectedTab}>
        {tabs.map(t => {
          return (
            <TabPanel style={{ width: '100%', padding: '0' }} key={t.id} value={t.id}>
              {t.component}
            </TabPanel>
          );
        })}
      </TabContext>
    </SquarePaper>
  );
};

export default DayOfWeekTables;
