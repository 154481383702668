import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 38,
  },
});
