import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()({
  divider: {
    margin: '0 !important',
  },
  addHoliday: {
    textAlign: 'right',
    marginBottom: 5,
    marginRight: 15,
  },
});
